import './recipe-teaser-list.scss';

// import { Slider } from './../../components/slider/slider';
import { A11y, Navigation } from 'swiper';

class RecipeTeaserList {
    constructor ($element, options) {
        const defaults = {
            initAttr: 'data-recipeteaserlist'
        };

        this.settings = Object.assign({}, defaults, options);
        this.$recipeTeaserList = $element;
        this.$recipeTeaserListSlider = this.$recipeTeaserList.querySelector('[' + this.settings.initAttr + '="slider"]');
        this.$recipeTeaserListSlides = this.$recipeTeaserList.querySelectorAll('[' + this.settings.initAttr + '="slide"]');
        this.recipeTeaserListSlider = null;

        this.resizeHandler = window.resizeHandler;
        this.sliderActive = false;

        if (this.$recipeTeaserListSlides.length > 1) {
            import(
                /* webpackChunkName: "slider/slider.js" */
                './../../components/slider/slider').then(({ Slider }) => {
                this.Slider = Slider;
                this.initialize();
            });
        }
        // this.initialize();
    }

    initialize () {
        this.checkSize();
        this.resizeHandler.customFunctions.push(() => {
            this.checkSize();
        });
    }

    checkSize () {
        if (window.innerWidth < 768 && this.$recipeTeaserListSlides.length > 1) {
            if (this.sliderActive === false) {
                this.initSlider();
            }
        } else if (window.innerWidth < 1024 && this.$recipeTeaserListSlides.length > 2) {
            if (this.sliderActive === false) {
                this.initSlider();
            }
        } else if (window.innerWidth >= 1024 && this.$recipeTeaserListSlides.length > 3) {
            if (this.sliderActive === false) {
                this.initSlider();
            }
        } else {
            if (this.sliderActive === true) {
                this.destroySlider();
                this.sliderActive = false;
            }
        }
    }

    initSlider () {
        this.recipeTeaserListSlider = new this.Slider(this.$recipeTeaserListSlider, {
            modules: [A11y, Navigation],
            speed: 500,
            slidesPerView: 'auto',
            slidesPerGroup: 1,
            centeredSlides: false,
            centeredSlidesBounds: true,
            initAttr: this.settings.initAttr,
            centerInsufficientSlides: true,
            onInit: () => {
                this.$recipeTeaserList.classList.add('recipe-teaser-list--slider');
            },
            breakpoints: {
                320: {
                    centeredSlides: true
                },
                768: {
                    slidesPerGroup: 2
                },
                1024: {
                    slidesPerGroup: 3
                }
            }
        });

        this.sliderActive = true;
    }

    destroySlider () {
        if (typeof this.recipeTeaserListSlider !== 'undefined' && this.recipeTeaserListSlider !== null) {
            this.$recipeTeaserList.classList.remove('recipe-teaser-list--slider');
            this.recipeTeaserListSlider.destroy();
        }
    }
}

export { RecipeTeaserList };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $$recipeTeaserList = $context.querySelectorAll('[data-recipeteaserlist="root"]');
        for (let i = 0; i < $$recipeTeaserList.length; i++) {
            const $recipeTeaserList = $$recipeTeaserList[i];

            const recipeTeaserListAPI = new RecipeTeaserList($recipeTeaserList);
            $recipeTeaserList.API = recipeTeaserListAPI;
        }
    }
});
